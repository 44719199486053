import redaxios from 'axios';
import { update, addItem, addItems } from '@/api/cart';

// general events
import { ITEM_PROPERTY_BUNDLE_KEY, ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT } from '@/store/minicart/bundles/constants';
import { EVENT_MINICART_CLOSE, EVENT_MINICART_OPEN } from '@/store/events';
import {
    ITEM_PROPERTY_BOX_HANDLE,
    ITEM_PROPERTY_FREQUENCY_INTERVAL, ITEM_PROPERTY_FREQUENCY_UNIT,
    ITEM_PROPERTY_INVETERATE_SUBSCRIPTION, ITEM_PROPERTY_IS_LASH, PURCHASE_OPTION_ONETIME,
} from '@/store/minicart/boxes/constants';

import Swiper, { Autoplay, Navigation } from 'swiper';
import { isProductHasOnlyDefaultVariant } from '@/api/helpers/product';
import { ITEM_PROPERTY_PURCHASE_OPTION, ITEM_PROPERTY_SELLING_PLAN } from '@/store/minicart/shopify/cart/constants';
import { windowVariableLoaded } from '@/helpers/wishlist';
import { formatMoney } from '@shopify/theme-currency';

export const REBUY_INIT = 'rebuy.init';

export const REBUY_BEFORE_LOADED_EVENT = 'rebuy.beforeLoaded';
export const REBUY_LOADED_EVENT = 'rebuy.loaded';

// cart events
export const REBUY_CART_READY_EVENT = 'rebuy:cart.ready';
export const REBUY_CART_ADD_EVENT = 'rebuy:cart.add';
export const REBUY_CART_CHANGE_EVENT = 'rebuy:cart.change';
export const REBUY_CART_ENRICHED_EVENT = 'rebuy:cart.enriched';

// smart cart events
export const REBUY_SMART_CART_INIT_EVENT = 'rebuy:smartcart.init';
export const REBUY_SMART_CART_READY_EVENT = 'rebuy:smartcart.ready';
export const REBUY_SMART_CART_SHOW_EVENT = 'rebuy:smartcart.show';
export const REBUY_SMART_CART_HIDE_EVENT = 'rebuy:smartcart.hide';
export const REBUY_SMART_CART_ITEM_INCREASE_EVENT = 'rebuy:smartcart.line-item-increase';
export const REBUY_SMART_CART_ITEM_DECREASE_EVENT = 'rebuy:smartcart.line-item-decrease';
export const REBUY_SMART_CART_ITEM_REMOVED_EVENT = 'rebuy:smartcart.line-item-removed';
export const REBUY_SMART_CART_ITEM_SWITCH_TO_SUBSCRIPTION_EVENT = 'rebuy:smartcart.item-switch-to-subscription';
export const REBUY_SMART_CART_ITEM_SWITCH_TO_ONETIME_EVENT = 'rebuy:smartcart.item-switch-to-one-time';

function initAnnouncementSliders() {
    const interval = setInterval(() => {
        const sliders = Array.from(document.querySelectorAll(`[data-minicart-announcement-bar-slider]`));
        if (!sliders) {
            return;
        }

        if (!sliders?.length) {
            return;
        }

        sliders?.forEach((slider) => {
            new Swiper(slider, {
                loop: true,
                autoplay: {
                    delay: 4000,
                },
                observer: true,
                resizeObserver: true,
                watchSlidesProgress: true,
                watchOverflow: true,
                modules: [Autoplay],
            });
        });

        clearInterval(interval);
    }, 100);
}

function initRecommendationSliders() {
    const interval = setInterval(() => {
        const sliders = Array.from(document.querySelectorAll(`[data-rebuy-recommendation-slider-container]`));
        if (!sliders) {
            return;
        }

        if (!sliders?.length) {
            return;
        }

        sliders?.forEach((slider) => {
            const sliderElement = slider.querySelector(`[data-rebuy-recommendation-slider]`);
            if (!sliderElement) {
                return;
            }

            const nextEl = slider.querySelector(`[data-arrow-next]`);
            const prevEl = slider.querySelector(`[data-arrow-prev]`);

            new Swiper(sliderElement, {
                initialSlide: 0,
                slidesPerView: 1,
                spaceBetween: 0,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 12,
                    }
                },
                observer: true,
                resizeObserver: true,
                watchSlidesProgress: true,
                watchOverflow: true,
                modules: [
                    Navigation,
                ],
                navigation: {
                    nextEl,
                    prevEl
                }
            });
        });

        clearInterval(interval);
    }, 100);
}

document.addEventListener(REBUY_SMART_CART_READY_EVENT, () => {
    initAnnouncementSliders();
});

document.addEventListener(REBUY_INIT, () => {
    initRecommendationSliders();
});

document.addEventListener(REBUY_SMART_CART_SHOW_EVENT, () => {
    initRecommendationSliders();
});
document.addEventListener('rebuy.refresh', () => {
    initRecommendationSliders();
});

document.addEventListener('rebuy.show', () => {
    initRecommendationSliders();
});

let upsellInitialized = false;

const RebuyUtils = {
    currency() {
        return window.Rebuy.Cart.getCurrency() || 'USD';
    },

    country() {
        return window.Shopify.country_code || 'US';
    },

    icon(name, { classes = '', viewBox = '', attributes = '' } = {
        classes: '',
        viewBox: '',
        attributes: '',
    }) {
        return `<svg class="icon icon-${name} ${classes}" ${viewBox ? `viewBox="${viewBox}"` : ''} preserveAspectRatio="xMinYMid meet" aria-hidden="true" ${attributes}><use href="${window?.theme?.spriteUrl.replace('[[name]]', name) || ''}"></svg>`;
    },
    is_free_gift() {
        return false;
    },
    is_bundle_parent(item) {
        if (!item) {
            return false;
        }

        const properties = item?.properties;
        if (!properties) {
            return false;
        }

        const keys = Object.keys(properties);
        if (!keys) {
            return false;
        }

        if (!keys?.length) {
            return false;
        }

        return !!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT);
    },
    is_bundle_child(item) {
        if (!item) {
            return false;
        }

        const properties = item?.properties;
        if (!properties) {
            return false;
        }

        const keys = Object.keys(properties);
        if (!keys) {
            return false;
        }

        if (!keys?.length) {
            return false;
        }

        if (!!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT)) {
            return false;
        }

        return !!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY);
    },
    is_monthly_box_item(item) {
        if (!item) {
            return false;
        }

        const selling_plan_allocation = item?.selling_plan_allocation;
        if (!selling_plan_allocation) {
            return false;
        }

        return !!selling_plan_allocation?.selling_plan;
    },
    bundle_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY);
        }) || [];
    },

    bundles() {
        const bundle_items = this.bundle_items();
        if (!bundle_items) {
            return [];
        }

        if (!bundle_items?.length) {
            return [];
        }

        const parent_items = bundle_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT);
        });

        if (!parent_items) {
            return [];
        }

        if (!parent_items?.length) {
            return [];
        }

        return parent_items?.map((parent_item) => {
            const parent_properties = parent_item?.properties;

            if (!parent_properties) {
                return parent_item;
            }

            const parent_keys = Object.keys(parent_properties);
            if (!parent_keys) {
                return parent_item;
            }

            if (!parent_keys?.length) {
                return parent_item;
            }

            const parent_bundle_key = parent_properties[ITEM_PROPERTY_BUNDLE_KEY];
            if (!parent_bundle_key) {
                return parent_item;
            }

            const item_components = bundle_items?.filter((bundle_item) => {
                const bundle_item_properties = bundle_item?.properties;
                if (!bundle_item_properties) {
                    return false;
                }

                const keys = Object.keys(bundle_item_properties);
                if (!keys) {
                    return false;
                }

                if (!keys?.length) {
                    return false;
                }

                if (!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY)) {
                    return false;
                }

                const bundle_key = bundle_item_properties[ITEM_PROPERTY_BUNDLE_KEY];
                if (!bundle_key) {
                    return false;
                }

                return bundle_key === parent_bundle_key;
            });

            if (!item_components) {
                return parent_item;
            }

            if (!item_components?.length) {
                return parent_item;
            }

            return {
                ...parent_item,
                bundle_key: parent_bundle_key,
                has_components: true,
                item_components,
                item_components_count: item_components?.length,
                final_line_price: (parent_item?.final_line_price || 0) + ((item_components || [])?.filter((item) => {
                    return item.key !== parent_item.key;
                })?.reduce((final_line_price, item) => {
                    return final_line_price + (item?.final_line_price || 0);
                }, 0) || 0),
                original_line_price: (parent_item?.original_line_price || 0) + ((item_components || [])?.filter((item) => {
                    return item.key !== parent_item.key;
                })?.reduce((original_line_price, item) => {
                    return original_line_price + (item?.original_line_price || 0);
                }, 0) || 0),
            };
        });
    },

    get_bundle(bundle_key) {
        const bundles = this.bundles();
        if (!bundles) {
            return null;
        }

        if (!bundles?.length) {
            return null;
        }

        return bundles?.find((bundle) => {
            return bundle?.bundle_key === bundle_key;
        }) || null;
    },

    subscription_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object?.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            if (`%%%${keys.join('%%%')}`.includes('%%%_rc')) {
                return false;
            }

            if (!!keys?.includes(ITEM_PROPERTY_INVETERATE_SUBSCRIPTION)) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BOX_HANDLE?.toString());
        }) || [];
    },

    subscription_items_total() {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return 0;
        }

        if (!subscription_items?.length) {
            return 0;
        }

        return subscription_items?.reduce((total, item) => {
            return total + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    subscription_items_total_usd() {
        return window.Currency.convert(this.subscription_items_total() / 100, this.currency(), 'USD') * 100;
    },

    subscription_lash_items() {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return [];
        }

        if (!subscription_items?.length) {
            return [];
        }

        return subscription_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys) {
                return false;
            }

            if (!keys?.includes(ITEM_PROPERTY_IS_LASH)) {
                return false;
            }

            return !![
                1,
                '1'
            ]?.includes(properties[ITEM_PROPERTY_IS_LASH]);
        });
    },

    subscription_lash_items_subtotal() {
        const subscription_lash_items = this.subscription_lash_items();
        if (!subscription_lash_items) {
            return 0;
        }

        if (!subscription_lash_items?.length) {
            return 0;
        }

        return subscription_lash_items?.reduce((subtotal, item) => {
            return subtotal + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    subscription_lash_items_subtotal_usd() {
        return window.Currency.convert(this.subscription_lash_items_subtotal() / 100, this.currency(), 'USD') * 100;
    },

    subscription_lash_items_count() {
        const subscription_lash_items = this.subscription_lash_items();
        if (!subscription_lash_items) {
            return 0;
        }

        if (!subscription_lash_items?.length) {
            return 0;
        }

        return subscription_lash_items?.reduce((count, item) => {
            return count + (item?.quantity || 0);
        }, 0) || 0;
    },

    shippable_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            return !!item?.requires_shipping;
        }) || [];
    },

    shippable_items_subtotal() {
        const shippable_items = this.shippable_items();
        if (!shippable_items) {
            return 0;
        }

        if (!shippable_items?.length) {
            return 0;
        }

        return shippable_items?.reduce((subtotal, item) => {
            return subtotal + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    shippable_items_subtotal_usd() {
        return window.Currency.convert(this.shippable_items_subtotal() / 100, this.currency(), 'USD') * 100;
    },

    monthly_box_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object?.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            if (!!keys?.includes(ITEM_PROPERTY_INVETERATE_SUBSCRIPTION)) {
                return false;
            }

            if (!!`%%%${keys?.join('%%%') || ''}`?.includes('%%%_rc')) {
                return false;
            }

            if (!!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY?.toString())) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BOX_HANDLE?.toString());
        }) || [];
    },

    boxes() {
        const boxes = [
            {
                key: 'monthly-box',
                title: 'Monthly Box',
                url: `/account?view=create-box`,
                image: 'https://cdn.shopify.com/s/files/1/1832/9335/files/luxe-box_541391be-1b84-4bc8-a536-7f4aabff24eb.jpg',
                type: 'default',
                items: this.monthly_box_items(),
            },
        ]?.map((box) => {
            const selling_plan = (box.items || []).reduce((selected_selling_plan, item) => {
                const selling_plan_allocation = item?.selling_plan_allocation;
                if (!selling_plan_allocation) {
                    return selected_selling_plan;
                }

                const selling_plan = selling_plan_allocation?.selling_plan;
                if (!selling_plan) {
                    return selected_selling_plan;
                }

                const name = selling_plan?.name;
                if (!name) {
                    return selected_selling_plan;
                }

                const regex = /(?<order_interval_frequency>\d+)(\s+)?(?<order_interval_unit_type>day|week|month|year)/gm;
                const rechargeData = regex?.exec(name?.toLowerCase())?.groups;

                const order_interval_frequency = rechargeData?.order_interval_frequency || null;
                if (!order_interval_frequency) {
                    return selected_selling_plan;
                }

                const order_interval_unit_type = rechargeData?.order_interval_unit_type || null;
                if (!order_interval_unit_type) {
                    return selected_selling_plan;
                }

                return {
                    ...selling_plan,
                    name: `Delivered every ${order_interval_frequency} ${order_interval_unit_type}${order_interval_frequency > 0 ? `s` : ``}`,
                    order_interval_frequency,
                    order_interval_unit_type,
                };
            }, null);

            return {
                ...box,
                selling_plan,
                final_line_price: (box.items || [])?.reduce((final_line_price, item) => {
                    return final_line_price + (item?.final_line_price || 0);
                }, 0) || 0,
                original_line_price: (box.items || [])?.reduce((original_line_price, item) => {
                    return original_line_price + (item?.original_line_price || 0);
                }, 0) || 0,
                order_interval_frequency: selling_plan?.order_interval_frequency,
                order_interval_unit_type: selling_plan?.order_interval_unit_type,
                count: box?.items?.reduce((count, item) => {
                    return count + (item?.quantity || 0);
                }, 0) || 0,
            };
        })?.filter((box) => {
            return !!box?.selling_plan;
        }) || [];

        if (!boxes) {
            return [];
        }

        if (!boxes?.length) {
            return [];
        }

        return boxes;
    },

    box() {
        const boxes = this.boxes();
        if (!boxes) {
            return null;
        }

        if (!boxes?.length) {
            return null;
        }

        const box = boxes[0];
        if (!box) {
            return null;
        }

        return box;
    },

    regular_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return true;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return true;
            }

            if (!keys?.length) {
                return true;
            }

            const inveterate_subscription = properties[ITEM_PROPERTY_INVETERATE_SUBSCRIPTION];
            if (!!inveterate_subscription) {
                return true;
            }

            if (!!`%%%${keys.join('%%%') || ''}`?.includes('%%%_rc')) {
                return true;
            }

            const bundle_key = properties[ITEM_PROPERTY_BUNDLE_KEY];
            if (!!bundle_key) {
                return false;
            }

            const box_handle = properties[ITEM_PROPERTY_BOX_HANDLE];
            if (!!box_handle) {
                return false;
            }

            return true;
        }) || [];
    },

    setting(name) {
        const settings = window?.Theme?.settings;
        if (!settings) {
            return null;
        }

        return settings[name] || null;
    },

    translate(name) {
        return window.Theme.translate(name);
    },

    upsellInit(products) {
        if (upsellInitialized) {
            return;
        }
        window.RebuyUtils.products = {};
    
        products.forEach(product => {
            const productId = product.id;
    
            const updatedProduct = {
                selected_variant: product?.selected_variant,
                option1: product?.selected_variant?.option1,
                option2: product?.selected_variant?.option2,
                option3: product?.selected_variant?.option3,
            };
    
            window.RebuyUtils.products[productId] = updatedProduct;
        });

        upsellInitialized = true;
    
    },

    findVariantIdByOptions(product, optionIndex, selectedOption) {
        const productId = product.id * 1;

        if (window.RebuyUtils.products[productId]) {
            window.RebuyUtils.products[productId][optionIndex] = selectedOption;
        } else {
            console.error('Product not found in RebuyUtils.products');
            return null;
        }
    
        const selectedOptionsLower = [
            window.RebuyUtils.products[productId]?.option1,
            window.RebuyUtils.products[productId]?.option2,
            window.RebuyUtils.products[productId]?.option3
        ].map(option => option?.toLowerCase());
    
        const variant = product?.variants?.find(variant => {
            return [variant?.option1, variant?.option2, variant?.option3].every((option, index) => {
                return option?.toLowerCase() === selectedOptionsLower[index];
            });
        });
    
        if (!variant) {
            console.error('No matching variant found for selected options:', selectedOption);
            return null;
        }
    
        window.RebuyUtils.products[productId].selected_variant = variant;
        window.RebuyUtils.updateOptionSelectors(product, variant);
        product.selected_variant = variant;
        return variant.id;
    },
    

    updateOptionSelectors(product, currentVariant) {
        if (!product) {
            return;
        }
    
        const variants = product?.variants;
        if (!variants) {
            return;
        }
    
        if (!variants?.length) {
            return;
        }
    
        if (!currentVariant) {
            return;
        }

        if (product?.options?.length === 1) {
            return;
        }

        let parentEl = null;

        document.querySelectorAll('.upsell-widget__product').forEach((productBlock) => {
            if ((productBlock.dataset.productId * 1) === product.id) {
                parentEl = productBlock;
            }
        }
        );
    
        const option1ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option1 > option`);
        if (!option1ValueSelectors) {
            return;
        }
    
        if (!option1ValueSelectors?.length || option1ValueSelectors?.length === 1) {
            return;
        }
    
        option1ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });
    
        const option2 = currentVariant?.option2;
        const option3 = currentVariant?.option3;
    
        const existing_option1_values = [
            ...new Set(variants?.filter((variant) => ![
                option2 ? variant?.option2 === option2 : false,
                option3 ? variant?.option3 === option3 : false
            ]?.includes(false))?.map((variant) => variant?.option1) || [])
        ];
    
        option1ValueSelectors?.forEach((el) => {
            const value = el?.value || '';
            if (!value) {
                return;
            }
    
            if (!existing_option1_values?.includes(value) || product?.options?.length === 1) {
                el?.classList?.add('hide');
            }
        });
    
        // Filter Option2
        const option2ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option2 > option`);
        if (!option2ValueSelectors?.length) {
            return;
        }
    
        option2ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });
    
        const existing_option2_values = [
            ...new Set(variants?.filter((variant) => variant?.option1 === currentVariant?.option1)?.map((variant) => variant?.option2) || [])
        ];
    
        option2ValueSelectors?.forEach((el) => {
            const value = el?.value || '';
            if (!value || !existing_option2_values?.includes(value)) {
                el?.classList?.add('hide');
            }
        });
    
        // Filter Option3
        const option3ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option3 > option`);
        if (!option3ValueSelectors?.length) {
            return;
        }
    
        option3ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });
    
        const existing_option3_values = [
            ...new Set(variants?.filter((variant) => 
                variant?.option1 === currentVariant?.option1 && variant?.option2 === currentVariant?.option2
            )?.map((variant) => variant?.option3) || [])
        ];
    
        option3ValueSelectors.forEach((el) => {
            const value = el?.value || '';
            if (!existing_option3_values.includes(value)) {
                el?.classList?.add('hide');
            }
        });
    },
    
    async openModal(name, { teleport = 'body' } = {
        teleport: 'body',
    }) {
        const html = await redaxios.get('/', {
            params: {
                section_id: name,
            },
        }).then((response) => {
            return response.data;
        });

        if (!html) {
            return;
        }

        const modal = document.createElement('div');

        modal.innerHTML = html || '';

        const wrapper = document.querySelector(teleport) || document.body;
        if (!wrapper) {
            return;
        }

        wrapper?.appendChild(modal);
    },

    async remove_monthly_box() {
        const monthly_box_items = this.monthly_box_items();
        if (!monthly_box_items) {
            return;
        }

        if (!monthly_box_items?.length) {
            return;
        }

        await update(monthly_box_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    async remove_bundle(bundle_key) {
        const bundle_items = this.bundle_items();
        if (!bundle_items) {
            return;
        }

        if (!bundle_items?.length) {
            return;
        }

        const filtered_bundle_items = bundle_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            const item_bundle_key = properties[ITEM_PROPERTY_BUNDLE_KEY];
            if (!item_bundle_key) {
                return false;
            }

            return item_bundle_key === bundle_key;
        });

        if (!filtered_bundle_items) {
            return;
        }

        if (!filtered_bundle_items?.length) {
            return;
        }

        await update(filtered_bundle_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    inveterate_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return false;
        }

        if (!items?.length) {
            return false;
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_INVETERATE_SUBSCRIPTION);
        }) || [];
    },

    inveterate_product_already_in_cart() {
        const inveterate_items = this.inveterate_items();
        if (!inveterate_items) {
            return false;
        }

        return !!inveterate_items?.length;
    },

    inveterate_product() {
        const inveterate = window['inveterate'] || {};
        if (!inveterate) {
            return null;
        }

        const properties = inveterate?.properties;
        if (!properties) {
            return null;
        }

        const product = properties?.product;
        if (!product) {
            return null;
        }

        return product;
    },

    inveterate_variant() {
        const inveterate_product = this.inveterate_product();
        if (!inveterate_product) {
            return null;
        }

        const variants = inveterate_product?.variants;
        if (!variants) {
            return null;
        }

        if (!variants?.length) {
            return null;
        }

        return variants[0];
    },

    inveterate_variant_available() {
        const inveterate_variant = this.inveterate_variant();
        if (!inveterate_variant) {
            return false;
        }

        return !!inveterate_variant?.available;
    },

    async add_membership() {
        const inveterate_product = this.inveterate_product();
        const inveterate_variant = this.inveterate_variant();

        if (!inveterate_variant) {
            return;
        }

        const selling_plan_id = inveterate_product?.selling_plan_groups[0]?.selling_plans[0]?.id;

        await addItem({
            id: inveterate_variant.id,
            quantity: 1,
            properties: {
                [ITEM_PROPERTY_INVETERATE_SUBSCRIPTION]: true,
            },
            ...(selling_plan_id ? {
                selling_plan: selling_plan_id,
            } : {}),
        });
    },

    async remove_inveterate_items() {
        const inveterate_items = this.inveterate_items();
        if (!inveterate_items) {
            return;
        }

        if (!inveterate_items?.length) {
            return;
        }

        await update(inveterate_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    async toggle_inveterate_product() {
        if (this.inveterate_product_already_in_cart()) {
            return this.remove_inveterate_items();
        }

        return this.add_membership();
    },

    async updateItemDeliveryFrequency(item, order_interval_frequency, order_interval_unit_type, ...callbacks) {
        delete item.properties['_attribution'];
        delete item.properties['_source'];
        delete item.properties['_discount_value'];

        item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = order_interval_frequency;
        item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = order_interval_unit_type;

        return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), order_interval_unit_type?.toString(), ...callbacks);
    },

    subscription_options() {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        if (!subscription_items?.length) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const subscription_item = subscription_items[0];
        if (!subscription_item) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const product = subscription_item?.product;
        if (!product) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const { subscription_frequency = "30", subscription_interval = "day"} = product || {
            subscription_frequency: '30',
            subscription_interval: 'day',
        };

        return {
            subscription_frequency,
            subscription_interval,
        };
    },

    async switchToSubscription(item) {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        if (!subscription_items?.length) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const subscription_item = subscription_items[0];
        if (!subscription_item) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const product = subscription_item?.product;
        if (!product) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const { subscription_frequency = "30", subscription_interval = "day"} = product || {
            subscription_frequency: '30',
            subscription_interval: 'day',
        };

        return this.updateItemDeliveryFrequency(item, subscription_frequency, subscription_interval);
    },

    subscriptionOptionLabel(item) {
        const box = this.box();
        if (!box) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const { order_interval_frequency, order_interval_unit_type} = box || {
            order_interval_frequency: '30',
            order_interval_unit_type: 'day',
        };

        if (!order_interval_frequency) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, '30', 'day');
        }

        if (!order_interval_unit_type) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), 'day');
        }

        return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), order_interval_unit_type?.toString());
    },

    async updateMonthlyBoxDeliveryFrequency(order_interval_frequency) {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return [];
        }

        if (!subscription_items?.length) {
            return [];
        }

        const items = subscription_items?.reduce((updates, item) => {
            const selling_plan_id = item?.product?.selling_plan_groups?.reduce((selling_plans, selling_plan_group) => {
                return [
                    ...selling_plans,
                    ...(selling_plan_group?.selling_plans || []),
                ];
            }, [])?.find((selling_plan) => {
                return selling_plan?.order_interval_frequency?.toString() === order_interval_frequency?.toString();
            })?.id;

            return [
                ...updates,
                {
                    id: item.variant_id,
                    quantity: item.quantity,
                    properties: {
                        ...(item?.properties || {}),
                        [ITEM_PROPERTY_FREQUENCY_INTERVAL]: order_interval_frequency,
                        [ITEM_PROPERTY_FREQUENCY_UNIT]: 'day',
                        [ITEM_PROPERTY_SELLING_PLAN]: selling_plan_id,
                    },
                    ...(selling_plan_id ? {
                        selling_plan: selling_plan_id,
                    } : {}),
                },
            ];
        }, []) || [];

        await update(subscription_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});

        return addItems(items);
    },

    isProductHasOnlyDefaultVariant(product) {
        return isProductHasOnlyDefaultVariant(product);
    },

    async addUpsell(item) {
        delete item.properties['_attribution'];
        delete item.properties['_source'];

        const { subscription_frequency = "30", subscription_interval = "day"} = this.subscription_options();

        item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = subscription_frequency;
        item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = subscription_interval;
        item.properties[ITEM_PROPERTY_PURCHASE_OPTION] = PURCHASE_OPTION_ONETIME;

        return window.Rebuy.Cart.addItem(item);
    },

    subtotal() {
        const subtotal = window.Rebuy.Cart.subtotal();
        if (!subtotal) {
            return 0;
        }

        return subtotal;
    },

    subtotal_usd() {
        return window.Currency.convert(this.subtotal() / 100, this.currency(), 'USD') * 100;
    },

    customer() {
        return window?.globals?.customer || null;
    },

    customer_tags() {
        const customer = this.customer();
        if (!customer) {
            return [];
        }

        const tags = customer?.tags;
        if (!tags) {
            return [];
        }

        if (!tags?.length) {
            return [];
        }

        return tags;
    },

    is_active_member_customer() {
        const customer_tags = this.customer_tags();
        if (!customer_tags) {
            return false;
        }

        if (!customer_tags?.length) {
            return false;
        }

        const downcased_tags = customer_tags?.map((tag) => {
            return tag?.toLowerCase();
        }) || [];

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        if (!!downcased_tags?.includes('inveterate-cancelled')) {
            return false;
        }

        return !![
            'Inveterate Tier: Membership X',
            'Inveterate Tier: X Pass Monthly',
        ]?.map((tag) => {
            return !!downcased_tags?.includes(tag?.toLowerCase());
        })?.includes(true);
    },

    membership_pricing_available() {
        const inveterate_product_already_in_cart = this.inveterate_product_already_in_cart();
        if (!!inveterate_product_already_in_cart) {
            return true;
        }

        return this.is_active_member_customer();
    },

    is_active_subscriber_customer() {
        const customer_tags = this.customer_tags();
        if (!customer_tags) {
            return false;
        }

        if (!customer_tags?.length) {
            return false;
        }

        const downcased_tags = customer_tags?.map((tag) => {
            return tag?.toLowerCase();
        }) || [];

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        return !!downcased_tags?.includes('Active Product Subscription'?.toLowerCase());
    },

    free_shipping_rules() {
        return [
            // if country US and box price are over 45 USD
            {
                condition: ![
                    this.country() === 'US',
                    this.membership_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if country is US and customer is active subscriber and cart total > 45 USD
            {
                condition: ![
                    this.country() === 'US',
                    this.is_active_subscriber_customer(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if country is US and cart total > 50 USD
            {
                condition: ![
                    this.country() === 'US',
                    !this.membership_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal',
                    threshold: 15000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if more than 4 subscription lashes and country is US
            {
                condition: ![
                    this.country() === 'US',
                    this.subscription_lash_items_count() > 0,
                ]?.includes(false),
                settings: {
                    property: 'subscription_lash_items_count',
                    threshold: 4,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'Add <strong>{{ free_shipping_away_amount }}</strong> more lashes to your box to receive free shipping',
                    }
                }
            },

            // if country is not US and cart total > 45 USD
            {
                condition: ![
                    this.country() === 'GB',
                    this.membership_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            {
                condition: ![
                    this.country() === 'GB',
                    !this.membership_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if country is not us and member pricing available and cart total > 75 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.membership_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 7500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if country is not US and customer is active subscriber and cart total > 100 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.is_active_subscriber_customer(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 10000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    }
                }
            },

            // if country is not US and box price are over 100 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.subscription_items_total_usd() > 0,
                ]?.includes(false),
                settings: {
                    property: 'subscription_items_total_usd',
                    threshold: 10000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'Add <strong>{{ free_shipping_away_amount }}</strong> more to your box to receive free shipping',
                    }
                }
            },

            // if country is not US and cart total >= 150 USD
            {
                condition: ![
                    this.country() !== 'US',
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 15000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                }
            },
        ];
    },

    free_shipping_settings() {
        const free_shipping_rules = this.free_shipping_rules();
        if (!free_shipping_rules) {
            return null;
        }

        if (!free_shipping_rules?.length) {
            return null;
        }

        return free_shipping_rules?.find((settings) => {
            return !!settings?.condition;
        })?.settings || null;
    },

    is_free_shipping_reached() {
        const free_shipping_rules = this.free_shipping_rules();
        if (!free_shipping_rules) {
            return false;
        }

        if (!free_shipping_rules?.length) {
            return false;
        }

        return free_shipping_rules?.filter((free_shipping_rule) => {
            return !!free_shipping_rule?.condition;
        })?.map((free_shipping_rule) => {
            return this.calculate_away_amount(free_shipping_rule?.settings);
        })?.map((away) => {
            return away <= 0;
        })?.includes(true) || false;
    },

    free_shipping_rule_available() {
        return !!this.free_shipping_settings();
    },

    free_shipping_messages() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return null;
        }

        const messages = free_shipping_settings?.messages;
        if (!messages) {
            return null;
        }

        return messages;
    },

    free_shipping_away_message() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        const free_shipping_messages = this.free_shipping_messages();
        if (!free_shipping_messages) {
            return null;
        }

        const away_message = free_shipping_messages?.away;
        if (!away_message) {
            return null;
        }

        switch (free_shipping_settings?.property) {
            case 'subscription_lash_items_count': {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.free_shipping_away_amount());
            }
            default: {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.formatMoney(window.Currency.convert(this.free_shipping_away_amount() / 100, 'USD', this.currency()) * 100, window.Shopify.money_format));
            }
        }
    },

    free_shipping_reached_message() {
        const free_shipping_messages = this.free_shipping_messages();
        if (!free_shipping_messages) {
            return null;
        }

        const reached_message = free_shipping_messages?.reached;
        if (!reached_message) {
            return null;
        }

        return reached_message;
    },

    calculate_away_amount(free_shipping_settings) {
        const threshold = free_shipping_settings?.threshold;
        if (!threshold) {
            return 0;
        }

        switch (free_shipping_settings?.property) {
            case 'subscription_lash_items_count': {
                return Math.max(Math.floor(threshold - this.subscription_lash_items_count()), 0);
            }
            case 'subtotal': {
                return Math.max(Math.floor(threshold - this.subtotal()), 0);
            }
            case 'subtotal_usd': {
                return Math.max(Math.floor(threshold - this.subtotal_usd()), 0);
            }
            case 'shippable_items_subtotal': {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal()), 0);
            }
            case 'shippable_items_subtotal_usd': {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal_usd()), 0);
            }
            case 'subscription_items_total': {
                return Math.max(Math.floor(threshold - this.subscription_items_total()), 0);
            }
            case 'subscription_items_total_usd': {
                return Math.max(Math.floor(threshold - this.subscription_items_total_usd()), 0);
            }
            default: {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal_usd()), 0);
            }
        }
    },

    free_shipping_away_amount() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        return this.calculate_away_amount(free_shipping_settings);
    },

    free_shipping_percent_complete() {
        if (this.is_free_shipping_reached()) {
            return 100.0;
        }

        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        const threshold = free_shipping_settings?.threshold;
        if (!threshold) {
            return 0;
        }

        return (threshold - this.free_shipping_away_amount()) / threshold * 100;
    },

    formatMoney(...args) {
        return formatMoney(...args);
    },
    toggleOptions(productId) {
        const optionsWrapper = document.querySelector(`.upsell-widget__options.upsell-widget__options--${productId}`);
        const button = document.querySelector(`.upsell-widget__info-right__button.upsell-widget__info-right__button--${productId}`);
        const overlay = document.querySelector('.upsell-widget__overlay');

          if (optionsWrapper && button && overlay) {
            optionsWrapper.classList.toggle('upsell-widget__options--show');
            button.classList.toggle('rotate');
            overlay.classList.toggle('upsell-widget__overlay--show');
          }
    },
    closeOptions() {
        setTimeout(() => {
        const optionsWrappers = document.querySelectorAll('.upsell-widget__options');
        const overlay = document.querySelector('.upsell-widget__overlay');
        const buttons = document?.querySelectorAll(`.upsell-widget__info-right__button`);

        optionsWrappers.forEach((optionsWrapper) => {
                optionsWrapper?.classList?.remove('upsell-widget__options--show');
                overlay?.classList?.remove('upsell-widget__overlay--show');
                buttons?.forEach((button) => {
                    button?.classList?.remove('rotate');
                });
            });
        },300);
    },

    presentment_price(selected_variant) {
        if (!window?.Shopify?.currency?.active?.toLowerCase()) {
            return this.cents(selected_variant.price);
        }

        if (window?.Shopify?.currency?.active?.toLowerCase() === 'usd') {
            return this.cents(selected_variant.price);
        }

        const presentment_prices = selected_variant?.presentment_prices;
        if (!presentment_prices) {
            return 0;
        }

        const presentment_price = presentment_prices?.map((presentment_price) => {
            return presentment_price?.price;
        })?.find((price) => {
            return price?.currency_code?.toLowerCase() === window?.Shopify?.currency?.active?.toLowerCase();
        })?.amount;

        if (!presentment_price) {
            return this.cents(window.Currency.convert(selected_variant.price, 'USD', window.Shopify.currency.active) * 100);
        }

        return this.cents(presentment_price);
    },

    cents(price) {
        const converted_price = parseInt(price?.toString()?.replaceAll(".", "")?.replaceAll(",", "") || 0);
        if (!converted_price) {
            return 0;
        }

        return converted_price;
    },

    log(data) {
        console.log(data);

        return data;
    }
};

window.RebuyUtils = window.RebuyUtils || RebuyUtils;

document.addEventListener('click', async (event) => {
    const target = event?.target?.closest('[data-minicart-opener]');
    if (!target) {
        return;
    }

    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.show();
});

document.addEventListener(EVENT_MINICART_OPEN, async () => {
    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.show();
});

document.addEventListener(EVENT_MINICART_CLOSE, async () => {
    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.hide();
});

export default RebuyUtils;
